<template>
  <div>
     <div class="cart_box">
        <div class="cart_zz" @click="doclose"></div>
        <div class="cart_x">
            <div class="cart_header">
                <div class="left">
                    <div class="leftimg">
                        <img :src="goodInfo.topPic" alt="" srcset="">
                    </div>
                    <div class="leftp">
                       <div class="num">￥{{goodInfo.price}}</div>
                       <div class="text">请选择“{{typeText}}”</div>
                    </div>
                </div>
                <div class="right" @click="doclose">
                    <van-icon size="25" name="cross" />
                </div>
            </div>
            <div class="site" @click="selectAddress">
                <img class="imgdinwei" src="../../../../src/assets/image/dinwei.png" alt="">
                <div class="site_text">
                    <div class="text1">{{address}}</div>
                    <div class="text2">包邮 送运费险</div>
                </div>
                <img class="imgright" src="../../../../src/assets/image/right.png" alt="">
            </div>
            <div class="saix_jhe">
                <div class="saix" v-for="(item, index) in skuAttr" :key="index">
                    <div class="title">{{item.name}}</div>
                    <div class="saixxx">
                        <span @click="doSelect(index, indexval)" v-for="(itemval, indexval) in item.val" :key="indexval" :class="{'gaol': itemval.isflag}">{{itemval.name}}</span>
                    </div>
                </div>
                <div class="num_shop">
                    <div class="title">数量</div>
                    <div class="stepper"><van-stepper v-model="params.list[0].num" /></div>
                </div>
            </div>
            <div class="order_r">
                <van-cell title="订单备注" is-link :value="params.note"  @click="show = true"/>
            </div>
            <div class="order_r order_t">
                <van-radio-group v-model="checked">
                <div class="orderType zfb">
                    <div>
                        <img src="../../../../src/assets/image/zhifubao.png" alt=""><span>支付宝</span>
                    </div>
                    <div>
                      <van-radio  checked-color="#000" name="zfb"></van-radio >  
                    </div>
                </div>
                <div class="orderType weix" v-for="(item, index) in bindList" :key="index">
                    <div>
                        <img src="../../../../src/assets/image/bind.png" alt=""><span>{{item.cardNo}}</span><tt class="color_ref" @click="deleteBind(item.id, index)">移除</tt>
                    </div>
                    <div>
                      <van-radio  checked-color="#000" :name="item.id"></van-radio >
                    </div>
                </div>
                </van-radio-group>
                <van-cell class="addbind" title="添加银行卡" is-link icon="credit-pay" @click="goAddBind"/>
            </div>
            <div class="buy_but">
                <div class="shop_but" @click="doBuy">
                    立即购买
                </div>
            </div>
        </div>
        <van-popup v-model="show">
            <van-field
                v-model="params.note"
                rows="2" 
                autosize
                label="订单备注"
                type="textarea"
                maxlength="50"
                placeholder="请输入订单备注"
                show-word-limit
                />
        </van-popup>
        <van-popup position="bottom" v-model="showAddress">
            <van-address-list
                v-model="params.addressId"
                :list="list"
                default-tag-text="默认"
                @add="onAdd"
                @edit="onEdit"
                @select="onSelect"
                add-button-text="确定"
            />
        </van-popup>
     </div>
     <!-- <addBind v-if="addBindShow" /> -->
  </div>
</template>

<script>
import { createOrder, getMrAddress, orderPay, getAddressList } from '@/api/order' // 创建订单 获取默认地址 支付
import { getListBind, bankunBind, confirmPay } from '@/api/bind' // 绑卡列表 删除银行卡 支付确认
export default {
  name: 'buyNow',
  data() {
    return {
        chosenAddressId: '1',
        list: [
        ],
        show: false,
        value: 1,
        checked: 'zfb',
        checked1: false,
        goodInfo: {},
        typeText: '',
        skuAttr: [],
        codeValue: '',
        errorInfo: '',
        showKeyboard: false,
        params: {
            addressId: 0,
            note: "",
            list: [
                {
                    cartId: 0,
                    skuId: 0,
                    num: 1
                }
            ],
            payWay: 0
        },
        address: '', // 地址
        showAddress: false,
        addersstd: '',
        bindList: [], // 银行卡列表
        codeShow: false,
        payConfirm: {
            bankId: 0,
            smsCode: ''
        }
    };
  },
   watch: {
  },

  mounted() {
    var that = this
    this.goodInfo = this.$parent.goodInfo
    this.goodInfo.skuAttr.forEach((element, index) => {
        if (index == 0) {
            this.typeText = this.typeText + element.name
        } else {
            this.typeText = this.typeText + ', ' + element.name
        }
        that.skuAttr.push({
            name: element.name,
            val: []
        })
        element.val.forEach((item, i) => {
            if (i == 0) {
                that.skuAttr[index].val.push({
                    isflag: true,
                    name: item
                })
            } else {
                that.skuAttr[index].val.push({
                    isflag: false,
                    name: item
                })
            }
        })
    })
    this.getAddress()
    // 绑卡列表
    this.getListBind()
  },

  methods: {
    // 获取银行卡列表
    getListBind() {
        getListBind().then(res => {
            this.bindList = res.data
        })
    },
    // 移除银行卡
    deleteBind(id, index) {
        var that = this
        that.$dialog.confirm({
            title: '提示',
            message: '您确定移除银行卡吗？',
        })
        .then(() => {
            // 删除卡
            bankunBind({id: id}).then(res => {
                if (res.state == 200) {
                    that.bindList.splice(index, 1)
                }
            })
        })
        .catch(() => {
            // on cancel
        });
    },
    doclose() {
        this.$parent.isBuyNew = false
    },
    // 获取默认地址
    getAddress() {
        getMrAddress().then(res => {
            this.params.addressId = res.data.id
            this.address = res.data.name + ' ' + res.data.area + ' ' + res.data.details
        })
    },
    // 切换sku
    doSelect(index, indexval) {
        var that = this
        that.skuAttr.forEach((element, index1) => {
            if (index1 == index) {
                that.skuAttr[index].val.forEach((item, i) => {
                    if (i == indexval) {
                        item.isflag = true
                    } else {
                        item.isflag = false
                    }
                })
            }
        })
    },
    // 立即购买
    doBuy() {
        var that = this
        var arr = []
        that.skuAttr.forEach((element, index1) => {
            element.val.forEach((item, i) => {
                if (item.isflag == true) {
                    arr.push(item.name)
                }
            })
        })
        that.goodInfo.skus.forEach((item, index) => {
            if (item.attrs == JSON.stringify(arr)) {
                that.params.list[0].skuId = item.id
            }
        })
        // 创建订单
        createOrder(that.params).then(res => {
            if (res.state == 200) {
                // 开始支付
                var payData = {
                    payWay: that.checked == 'zfb'? 3 : 1, // 3-支付宝 1-银行卡
                    orderId: res.data.orderId,
                    bindId: that.checked == 'zfb'? 0 : Number(that.checked)
                }
                orderPay(payData).then(ress => {
                    if (ress.state == 200) {
                        if (payData.payWay == 3) { // 支付宝
                            that.$parent.isBuyNew = false
                            window.open(ress.data.url)
                        } else if (payData.payWay == 1) {
                            // 银行卡
                            // that.$layer.quicktips('支付成功')
                            that.errorInfo = ''
                            // 跳回去
                            that.$parent.isBuyNew = false
                            // 验证码验证
                            that.$parent.isBindBuy = true
                            that.$parent.payObj = {
                                bindId: Number(that.checked),
                                orderId: res.data.orderId
                            }
                        }
                    }
                })
            }
        })
    },
    // 选择地址
    selectAddress() {
        this.showAddress = true
        this.getList()
    },
    // 获取收货地址列表
    getList() {
        this.list = []
        getAddressList().then(res => {
            res.data.forEach(item => {
              this.list.push({
                    id: item.id,
                    name: item.name,
                    tel: item.phone,
                    address: item.area + ' ' + item.details,
                    isDefault: item.isDefault,
                    area: item.area,
                    details: item.details,
                    code: item.code
                })
            });
        })
    },
    onAdd() {
        // 选择这个地址
        this.address = this.addersstd
        this.showAddress = false
    },
    onEdit(item, index) {
        this.$router.push({
            name: `editAddress`,
            params: {
                id: item.id,
                obj: JSON.stringify(item)
            }
        })
    },
    // 切换的时候触发
    onSelect(item, index) {
        this.addersstd = item.name + ' ' + item.address
    },
    // 新增银行卡
    goAddBind() {
        this.$parent.addBindShow = true
    },
  },
};
</script>

<style lang="less" scoped>
.gaol{
    background: rgba(218,182,167,0.1) !important;
    border: 1px solid #DAB6A7;
    color: #DAB6A7 !important;
}
.cart_box{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 110;
    .cart_zz{
        position: fixed;
        top: 0rem;
        left: 0rem;
        width: 100vw;
        height: 100vh;
        background: #000;
        opacity: 0.6;
        z-index: 111;
    }
    .cart_x{
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        padding: 16rem;
        width: calc(100vw - 32rem);
        height: calc(80% - 32rem);
        padding-bottom: 92rem;
        background: #F6F7FA;
        z-index: 222;
        overflow-y: auto;
        overflow-x: hidden;
        .cart_header{
            display: flex;
            justify-content: space-between;
            .left{
                display: flex;
                align-items: end;
                .leftimg{
                    img{
                        width: 110rem;
                        height: 110rem;
                    }
                }
                .leftp{
                    margin-left: 10rem;
                    .num{
                        font-family: DIN, DIN;
                        font-weight: bold;
                        font-size: 19rem;
                        color: #333333;
                    }
                    .text{
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 13rem;
                        color: #7E8892;
                    }
                }
            }
        }
        .saix_jhe{
            background: #fff;
            padding: 10rem 15rem 20rem 15rem;
            margin-top: 10rem;
        }
        .saix{
            margin-top: 10rem;
            .title{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 15rem;
                color: #333333;
            }
            .saixxx{
                display: flex;
                flex-wrap: wrap;
                span{
                    padding: 10rem 25rem;
                    background: #F6F7FA;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 13rem;
                    color: #939297;
                    margin-top: 10rem;
                    margin-right: 10rem;
                }
            }
        }
        .num_shop{
            margin-top: 20rem;
            display: flex;
            justify-content: space-between;
                
            .title{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 15rem;
                color: #333333;
            }
        }
    }
    .shop_but{
        width: 343rem;
        height: 49rem;
        background: #000;
        line-height: 49rem;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #fff;
    }
    .buy_but{
        width: 100vw;
        height: 76rem;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.site{
    margin-top: 10rem;
    width: 313rem;
    height: 45rem;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15rem;
    .imgdinwei{
        width: 23rem;
    }
    .site_text{
        width: 265rem;
        margin-left: 13rem;
        .text1{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 15rem;
            color: #333333;
            line-height: 22rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .text2{
            font-family: PingFang-SC, PingFang-SC;
            font-weight: 400;
            font-size: 14rem;
            color: #7E8892;
            line-height: 22rem;
        }
    }
    .imgright{
        width: 7px;
    }
}
.order_r{
    margin-top: 10rem;
}
.order_t{
    background: #fff;
    padding: 0rem 15rem;
    .orderType{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50rem;
        margin: 0rem 16rem;
        border-bottom: 1rem solid #F6F7FA;
        span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15rem;
            margin-left: 8rem;
            color: #333333;
        }
    }
    .zfb{
        img{
            width: 18rem;
            vertical-align: bottom;
        }
    }
    .weix{
        border-bottom: none;
        img{
            width: 20rem;
            vertical-align: bottom;
        }
    }
}
.van-popup{
    width: 100vw;
    padding: 20rem 0rem;
}
.van-tag--danger{
    background: #000;
    border-radius: 2rem;
}
 /deep/ .van-radio__icon--checked .van-icon{
    background-color: #000;
    border-color: #000;
}
.van-address-list__bottom{
    border-top: none;
}
.van-address-list__bottom .van-button {
    background-color: #E0BFB1;
    border-color: #E0BFB1;
    border-radius: 4rem;
}
.van-address-item{
    border-bottom: 1rem solid #F6F7FA;
}
.color_ref{
    color: #FF1616;
    margin-left: 20rem;
    font-size: 16rem;
    font-weight: bold;
}
.addbind /deep/ .van-icon{
    font-size: 20rem;
    margin-right: 10rem;
}
.addbind{
    // padding-left: 0rem;
    padding-right: 5rem;
}
.code_tit{
    text-align: center;
    font-size: 20rem;

}
.password_yz{
    margin-top: 30rem;
}
</style>
